import styled from 'styled-components'

export const StepperStlyed = styled.div`
  .stepper.stepper-pills .stepper-item.current .stepper-label .stepper-title {
    color: #3f4254;
  }
  .stepper.stepper-pills .stepper-item .stepper-label .stepper-title {
    color: #7e8299;
  }
`
