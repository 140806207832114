import Tooltip from 'components/Tooltip'
import { ICheckedError } from 'pages/Commercial/MenuFoods/Register/contexts'
import React, {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useCallback,
  ReactElement,
  useState
} from 'react'
import {
  Controller,
  FormProvider,
  RegisterOptions,
  useForm,
  UseFormRegister
} from 'react-hook-form'

import { IconBaseProps } from 'react-icons'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { FiAlertCircle } from 'react-icons/fi'
import { genericMaskWithTwoZeroWithPoint } from '../../utlis/mask'
import {
  Container,
  EndMessage,
  Error,
  SelectContainer,
  TextAreaContainer
} from './styles'

import { useLocation } from 'react-router-dom'

export default function Form({
  defaultValues,
  children,
  onSubmit,
  hasErrors,
  id
}: any) {
  const methods = useForm({ defaultValues, shouldUnregister: true })
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = methods
  const url = useLocation().pathname
  const [actualId, setActualId] = useState(undefined)
  useEffect(() => {
    const updatedId = getValues().id
    if (updatedId === undefined || updatedId !== actualId) {
      if (defaultValues?.id !== undefined) {
        setActualId(updatedId)
        setValue('updatedId', defaultValues.id)
      }
      reset(defaultValues)
    }
  }, [defaultValues, getValues, reset, setValue, url])

  if (hasErrors) hasErrors(errors)

  const registeredField = useCallback(
    (child: ReactElement) => {
      if (child.props.controlled) {
        child.props.value && setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.fullControlled) {
        setValue(child.props.name, child.props.value)
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      if (child.props.price) {
        return (
          <Controller
            key={child.props.name}
            shouldUnregister={true}
            control={control}
            name={child.props.name}
            rules={child.props.rules}
            render={({ field }) => {
              return React.createElement(child.type, {
                ...{
                  ...child.props,
                  ...field,
                  onChange: (e: any) => {
                    field.onChange(e)
                    child.props.onChange && child.props.onChange(e)
                    setValue(
                      child.props.name,
                      genericMaskWithTwoZeroWithPoint(e.target.value)
                    )
                  },
                  errors,
                  key: child.props.name
                }
              })
            }}
          />
        )
      }
      return React.createElement(child.type, {
        ...{
          ...child.props,
          register,
          errors,
          key: child.props.name
        }
      })
    },
    [control, errors, register, setValue]
  )

  const buildChildren = useCallback(
    (children: ReactElement, key = 0): any => {
      if (Array.isArray(children)) {
        return children.map((child: ReactElement, index) => {
          return buildChildren(child, index)
        })
      }

      if (children?.props?.children) {
        const childCopy = React.cloneElement(children, {
          key,
          children: buildChildren(children.props.children)
        })
        return childCopy
      }
      return children?.props?.name ? registeredField(children) : children
    },
    [registeredField]
  )

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} id={id}>
        {buildChildren(children)}
      </form>
    </FormProvider>
  )
}

interface RulesProps extends RegisterOptions {
  position?: string
}

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegister<any>
  name: string
  rules?: RulesProps
  endMessage?: string
  hasError?: any
  errors?: any
  label?: string
  controlled?: boolean
  fullControlled?: boolean
  price?: boolean
  icon?: React.ComponentType<IconBaseProps>
  nestedError?: ICheckedError
  errorMessage?: string
  mask?: {
    type?: string
    prefixInputsData?: string
    excludeFields?: string[]
    lazy?: boolean
    customFields?: {
      name: string
      type: 'street' | 'district' | 'city' | 'state'
      api_field: string
    }[]
  }
}

export function Input({
  register,
  name,
  label,
  icon: Icon,
  rules,
  hasError,
  errors,
  className,
  endMessage,
  nestedError,
  ...rest
}: IInputProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  if (nestedError?.hasError) {
    error = {
      message: nestedError?.messageError
    }
  }

  return (
    <Container
      className={className}
      error={
        error?.type === 'required' ||
        error?.message !== undefined ||
        hasError?.error
      }
    >
      {Icon && <Icon size={20} />}
      {label && (
        <label
          htmlFor={name}
          className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
        >
          {label}
        </label>
      )}
      <div>
        <input
          {...(register && register(name, rules))}
          {...rest}
          id={name}
          autoComplete="off"
          className="form-control form-control-solid"
        />
        {error?.message && (
          <Error title={error.message} position={rules?.position}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message} position={rules?.position}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error
            title={`O campo ${label} é obrigatório`}
            position={rules.position}
          >
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {!!endMessage && <EndMessage>{endMessage}</EndMessage>}
      </div>
    </Container>
  )
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  register?: UseFormRegister<any>
  options: Array<{
    value: string | number
    name: string | number
    disabled?: boolean
    hidden?: boolean
  }>
  name: string
  label?: string
  rules?: RegisterOptions
  errors?: any
  controlled?: boolean
  blank?: boolean
  titleTooltip?: string
  customizedError?: string
  blankSelectMessage?: string
  nestedError?: ICheckedError
}

export function Select({
  register,
  options,
  name,
  label,
  rules,
  errors,
  className,
  blank,
  titleTooltip,
  blankSelectMessage,
  customizedError,
  nestedError,
  ...rest
}: SelectProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  if (customizedError) {
    error = true
  }
  if (nestedError?.hasError) {
    error = true
  }

  return (
    <SelectContainer erro={error} className={className}>
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
          {titleTooltip && (
            <Tooltip title={titleTooltip} width={275}>
              <AiOutlineQuestionCircle />
            </Tooltip>
          )}
          {customizedError && (
            <Tooltip title={customizedError} width={200}>
              <FiAlertCircle />
            </Tooltip>
          )}
        </label>
      )}

      <div className="select-div">
        {nestedError?.hasError && (
          <Error title={nestedError?.messageError} type="select">
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        <select
          {...(register && register(name, rules))}
          {...rest}
          id={name}
          autoComplete="off"
          className="form-select form-select-solid fw-boldl"
        >
          {blank && (
            <option key={0} value="" disabled selected>
              {blankSelectMessage || 'Selecione'}
            </option>
          )}
          {options.map(option => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </SelectContainer>
  )
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  register?: UseFormRegister<any>
  name: string
  rules?: RulesProps
  errors?: any
  label?: string
}

export function Textarea({
  register,
  name,
  label,
  rules,
  errors,
  className,
  ...rest
}: TextareaProps) {
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  return (
    <TextAreaContainer erro={error?.type === 'required'} className={className}>
      {label && (
        <label htmlFor={name} className="col-form-label fw-bold fs-6">
          {label}
        </label>
      )}
      <div>
        <textarea
          id={name}
          {...(register && register(name, rules))}
          className="form-control form-control-lg form-control-solid"
          {...rest}
        />
        {error?.type === 'required' && (
          <Error
            title={`O campo ${label} é obrigatório`}
            position={rules.position}
          >
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </TextAreaContainer>
  )
}
