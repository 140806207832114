export function getFirstWorkingDay(limit: number) {
  const today = new Date()
  let final = new Date()
  const newDate = new Date()
  newDate.setDate(today.getDate() + limit)
  final = newDate
  if (newDate.getDay() === 6) {
    final.setDate(newDate.getDate() + 2)
  } else if (newDate.getDay() === 0) {
    final.setDate(newDate.getDate() + 1)
  }
  return final
}
