import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLoading } from '../../hooks/loading'
import { useToast } from '../../hooks/toast'

import { Container } from './styles'

export const UnauthenticatedRouteError: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToast()
  const { disableLoading } = useLoading()

  useEffect(() => {
    disableLoading()

    setTimeout(() => {
      addToast({
        title: 'Pagina não autorizada',
        description: 'O usuario não tem autorização para acessar esta pagina',
        type: 'error'
      })
    }, 500)

    history.action === 'POP' ? history.push('/') : history.go(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Container />
}
