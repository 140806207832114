import { useToast } from 'hooks/toast'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

interface IChooseDaysFrequencyForm {
  frequencyDaysOfWeek: string[]
  setFrequencyDaysOfWeek: React.Dispatch<React.SetStateAction<string[]>>
  handleClickOnCloseModalFrequency: () => void
  selectedPlanFrequency: ISelectedPlanFrequency
  defaultValues: string[]
}

const DAYS_OF_WEEK = [
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira'
]

const ChooseDaysFrequencyForm = ({
  frequencyDaysOfWeek,
  setFrequencyDaysOfWeek,
  handleClickOnCloseModalFrequency,
  selectedPlanFrequency,
  defaultValues
}: IChooseDaysFrequencyForm) => {
  // [{dish_type: 'Fruta', dish_type_id:1 dishes: [{name: '', id: , is_default_dish: }] }]
  const { handleSubmit } = useForm()
  const { addToast } = useToast()
  //   selectedPlanFrequency

  useEffect(() => {
    if (defaultValues.length) {
      setFrequencyDaysOfWeek(defaultValues)
    }
  }, [defaultValues, setFrequencyDaysOfWeek])

  let numberOfDaysToSelect: number
  if (selectedPlanFrequency?.frequency.match(/\d/)) {
    numberOfDaysToSelect = +selectedPlanFrequency?.frequency.match(/\d/)[0]
  }
  const onSuccessSubmit = () => {
    if (frequencyDaysOfWeek.length !== numberOfDaysToSelect) {
      addToast({
        type: 'error',
        title: 'Preenchimento incompleto.',
        description:
          'Por favor preencha o número correto de dias contratados pelo plano.'
      })
      return
    }
    handleClickOnCloseModalFrequency()
  }

  const addDay = (dayToAdd: string) => {
    setFrequencyDaysOfWeek(old => [...old, dayToAdd])
  }

  const removeDay = (dayToDelete: string) => {
    setFrequencyDaysOfWeek(old => {
      return old.filter(day => day !== dayToDelete)
    })
  }
  const disableNotSelectedWeekDays = (dayName: string) => {
    if (frequencyDaysOfWeek.length === numberOfDaysToSelect) {
      const isAdded = frequencyDaysOfWeek.some(day => day === dayName)
      return !isAdded
    }
    return false
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSuccessSubmit)}>
        <p className="pb-3">
          Selecione <b>{numberOfDaysToSelect}</b> dias da semana:{' '}
        </p>
        {DAYS_OF_WEEK.map(day => {
          return (
            <div key={day} className="gap-2 d-flex">
              <input
                type="checkbox"
                name={day}
                id={day}
                value={day}
                defaultChecked={defaultValues.includes(day)}
                disabled={disableNotSelectedWeekDays(day)}
                onChange={e =>
                  e.target.checked
                    ? addDay(e.target.value)
                    : removeDay(e.target.value)
                }
              />
              <label htmlFor={day}>{day}</label>
            </div>
          )
        })}
        <button className="btn btn-sm btn-primary float-end">Confirmar</button>
      </form>
    </div>
  )
}

export default ChooseDaysFrequencyForm
