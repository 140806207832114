export const apiCreate = (): string => '/commercial/contracts/create/'

export const apiGetStudent = (id: string): string =>
  `/commercial/students/view/${id}`

export const apiListMenuFoodTypes = (
  turn: string,
  institution_id: number
): string =>
  `/commercial/menuFoodTypes?period=${turn}&institution_id=${institution_id}`

export const apiListInstitution = (): string => '/commercial/institutions/'

export const apiGetContract = (id: string): string =>
  `/commercial/contracts/view/${id}`

export const apiListMenuFoodDishes = (
  institution_id: string,
  menu_food_type_id: string
): string =>
  `/commercial/menuFoods?institution_id=${institution_id}&menu_food_type_id=${menu_food_type_id}`
//   `/commercial/menuFoods?institution_id=${institution_id}&menu_food_type_id=${menu_food_type_id}`

export const apiListPlans = (): string => '/commercial/plans'

export const apiUpdate = (id: string): string =>
  `/commercial/institutions/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/institutions/delete/${id}`

export const apiGetPdfContract = (id: string): string =>
  `/commercial/contracts/generatePdf/${id}`

export const apiGetPdfPreviewContract = (): string =>
  '/commercial/contracts/generatePdfPreview'

export const apiCreateFinancialMoviment = () => '/financial/financialMoviments'

export const apiListFinancialMoviment = () => '/financial/financialMoviments'

export const apiGetPdfFinancialMoviment = (id: string) =>
  `/financial/financialMovimentsPayments/${id}`
