import React, { createContext, useCallback, useState, useContext } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import api from '../services/api'

interface AuthContextData {
  backupHistory: string
  signOut(): void
  userLogged(): boolean
  setHistory(string: string): void
  setToken(token: { token: string }): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [backupHistory, setBackupHistory] = useState('')

  const setToken = useCallback(async ({ token }) => {
    console.log(token)
    localStorage.setItem('@Merenderia:token', token)
    api.defaults.headers.authorization = `Bearer ${token}`
  }, [])

  const setHistory = useCallback((history: string) => {
    setBackupHistory(history)
  }, [])

  const userLogged = useCallback(() => {
    return true
    const token = localStorage.getItem('@Merenderia:token')
    if (token) {
      const decode = jwtDecode<JwtPayload>(token)
      const currentDate = Date.now().valueOf() / 1000
      if (decode.exp < currentDate) {
        return false
      } else {
        return true
      }
    }
    return false
  }, [])

  const signOut = useCallback(() => {
    localStorage.removeItem('@Merenderia:token')
  }, [])

  return (
    <AuthContext.Provider
      value={{
        setHistory,
        setToken,
        backupHistory,
        userLogged,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
