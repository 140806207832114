import { convertValueMaskInNumberWithTwoZero, currencyMask } from 'utlis/mask'
import { ButtonCircle, Card, CardSubText } from '../FormRegister/styles'
import Boleto from 'assets/image/boleto.png'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'
import { CardSummary, SummaryContractRight, SummarySubText } from './styles'
import { Select } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { OPTIONS_FORM_BOLETO } from 'commons/constants/commercial'
import { useEffect } from 'react'
import { getFirstWorkingDay } from 'utlis/date'

interface IContractSummary {
  contract_plans: IContractPlanDisplay[]
  total: string
  discount: string
  subTotal: string
  dueDate: string | Date
  setDueDate: React.Dispatch<React.SetStateAction<string | Date>>
  favoriteDay: string
  setFavoriteDay: React.Dispatch<React.SetStateAction<string>>
  startDate: Date
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  selectedPayamentForm: TFormPayament
  setSelectedPayamentForm: React.Dispatch<React.SetStateAction<TFormPayament>>
  setRecurrenceNumber: React.Dispatch<React.SetStateAction<number>>
  setFirstInstallmentValue: React.Dispatch<React.SetStateAction<string>>
}

const Summary = ({
  total,
  discount,
  subTotal,
  contract_plans,
  dueDate,
  setDueDate,
  favoriteDay,
  setFavoriteDay,
  selectedPayamentForm,
  setSelectedPayamentForm,
  setRecurrenceNumber,
  setFirstInstallmentValue,
  startDate,
  setStartDate
}: IContractSummary) => {
  const dueDateLimit = process.env.REACT_APP_DUE_DATE_LIMIT
    ? Number(process.env.REACT_APP_DUE_DATE_LIMIT)
    : 2

  const isMonthly = contract_plans[0].periodicity_payament === 'Mensal'

  function getWorkingDays(startDate: Date, endDate: number | Date) {
    let result = 0
    const currentDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    )
    // eslint-disable-next-line no-unmodified-loop-condition
    while (currentDate <= endDate) {
      const weekDay = currentDate.getDay()
      if (weekDay !== 0 && weekDay !== 6) result++
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return result
  }

  function getDaysInMonth(iMonth: number, iYear: number) {
    return new Date(iYear, iMonth, 0).getDate()
  }
  const daysInMonth = getDaysInMonth(
    startDate.getMonth() + 1,
    startDate.getFullYear()
  )
  // const begin = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const end = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    daysInMonth
  )
  // const workingDaysInMonth = getWorkingDays(begin, end)
  const workingDaysInMonth = process.env.REACT_APP_WORKING_DAYS_IN_MONTH
    ? Number(process.env.REACT_APP_WORKING_DAYS_IN_MONTH)
    : 22
  const workingDaysInMonthFromStart = getWorkingDays(startDate, end)
  const dayValue =
    convertValueMaskInNumberWithTwoZero(total) / workingDaysInMonth
  const monthValue = dayValue * workingDaysInMonthFromStart
  let finalValue = total
  if (isMonthly) {
    if (convertValueMaskInNumberWithTwoZero(total) >= monthValue) {
      finalValue = currencyMask(monthValue)
    }
  }

  const allowedDatesOptions =
    process.env.REACT_APP_FAVORITE_DAYS_OPTIONS?.split(',') || ['5', '10']

  useEffect(() => {
    if (startDate) {
      const newDate = new Date(startDate)
      newDate.setDate(startDate.getDate() + dueDateLimit)
      const date = newDate
      if (newDate.getDay() === 6) {
        date.setDate(newDate.getDate() + 2)
      } else if (newDate.getDay() === 0) {
        date.setDate(newDate.getDate() + 1)
      }
      setDueDate(date)
    }
  }, [dueDateLimit, setDueDate, startDate])
  useEffect(() => {
    setRecurrenceNumber(OPTIONS_FORM_BOLETO(12, finalValue)[0].value)
    setFirstInstallmentValue(finalValue)
  }, [setRecurrenceNumber, finalValue, setFirstInstallmentValue])

  useEffect(() => {
    setSelectedPayamentForm('Boleto')
  }, [setSelectedPayamentForm])

  return (
    <div className="row mb-3">
      <div>
        <h3 className="col-form-label fw-bold fs-5">Método de pagamento</h3>
      </div>
      <div className="row mb-5">
        <div className="row col-md-9 align-content-start">
          <div className="row col-md-12">
            <div className="col-xl-4 mb-5">
              <Card
                isSelected={selectedPayamentForm === 'Boleto'}
                className="d-flex align-items-center justify-content-center"
              >
                <ButtonCircle
                  type="button"
                  onClick={() => {
                    setSelectedPayamentForm('Boleto')
                  }}
                  style={{ width: '22px', height: '22px' }}
                >
                  {selectedPayamentForm === 'Boleto' ? (
                    <BsCheckCircle fontSize={22} color="#009EF7" />
                  ) : (
                    <BsCircle fontSize={20} color="#A1A5B7" />
                  )}
                </ButtonCircle>
                <img src={Boleto} alt="Boleto" />
              </Card>
            </div>
            {/*             <div className="col-xl-4">
              <Card
                isSelected={selectedPayamentForm === 'Paypal'}
                className="d-flex align-items-center justify-content-center"
              >
                <ButtonCircle
                  type="button"
                  onClick={() => {
                    setSelectedPayamentForm('Paypal')
                  }}
                  style={{ width: '22px', height: '22px' }}
                >
                  {selectedPayamentForm === 'Paypal' ? (
                    <BsCheckCircle fontSize={22} color="#009EF7" />
                  ) : (
                    <BsCircle fontSize={20} color="#A1A5B7" />
                  )}
                </ButtonCircle>
                <img src={CardImage} alt="CardImage" height={91} />
              </Card>
            </div>
 */}{' '}
          </div>
          {isMonthly && (
            <div>
              <DateInput
                label="Selecione o início do contrato"
                labelStyle={{ fontWeight: 'bold' }}
                className="col-md-3"
                name="start_date"
                onChange={date => setStartDate(date)}
                minDate={getFirstWorkingDay(dueDateLimit)}
                selected={startDate}
                controlled
              />
            </div>
          )}

          {selectedPayamentForm === 'Boleto' && (
            <>
              <div className="row">
                <div className="col-md-5">
                  <label
                    style={{
                      padding: '11px 0px',
                      fontWeight: 500,
                      color: '#3f4254',
                      fontSize: '14px'
                    }}
                  >
                    <p>{`Primeira mensalidade: ${finalValue}.`}</p>
                    {isMonthly && (
                      <p> {`As próximas serão no valor de ${total}.`}</p>
                    )}
                  </label>
                </div>
                <DateInput
                  name="number_recurrence"
                  rules={{ required: true }}
                  selected={dueDate as Date}
                  onChange={() => {
                    //
                  }}
                  className="col-md-3"
                  label={'Primeiro vencimento'}
                  disabled
                />
              </div>
              <div className="row">
                <Select
                  options={allowedDatesOptions.map(day => ({
                    value: day,
                    name: `Dia ${day}`
                  }))}
                  name="favorite_date"
                  rules={{ required: true }}
                  value={favoriteDay as string}
                  onChange={event => setFavoriteDay(event.target.value)}
                  className="col-md-3"
                  label={'Melhor dia de vencimento das demais parcelas'}
                  blank
                />
              </div>
            </>
          )}
        </div>
        <div className="row col-md-3">
          <SummaryContractRight>
            <h3 style={{ color: '#3F4254' }}>RESUMO DA CONTRATAÇÃO</h3>
            <div className="gap-2 d-flex flex-column">
              {contract_plans.map(contract => {
                return (
                  <CardSummary key={contract.plan_id}>
                    <div className="d-flex justify-content-between mb-2">
                      <h3 style={{ color: '#3F4254' }}>
                        {contract.menu_food_type_name}
                      </h3>
                      <CardSubText>{contract.periodicity_payament}</CardSubText>
                    </div>

                    <p className="d-flex justify-content-between mb-2">
                      <SummarySubText>Valor do plano</SummarySubText>{' '}
                      <SummarySubText>
                        {currencyMask(contract.plan_value)}
                      </SummarySubText>
                    </p>
                    <p className="d-flex justify-content-between">
                      <SummarySubText fontSize={13} color={'#464E5F'}>
                        Total
                      </SummarySubText>{' '}
                      <SummarySubText fontSize={13} color={'#464E5F'}>
                        {currencyMask(contract.value_with_discount)}
                      </SummarySubText>
                    </p>
                  </CardSummary>
                )
              })}
              {discount !== 'R$ 0,00' && (
                <>
                  <p className="d-flex justify-content-between mb-2">
                    <SummarySubText fontSize={14}>Subtotal</SummarySubText>
                    <SummarySubText fontSize={14}>{subTotal}</SummarySubText>
                  </p>
                  <p className="d-flex justify-content-between mb-2">
                    <SummarySubText fontSize={14}>Desconto</SummarySubText>
                    <SummarySubText fontSize={14}>{discount}</SummarySubText>
                  </p>
                </>
              )}
              <p className="d-flex justify-content-between mb-2">
                <SummarySubText color="#009EF7" fontSize={16}>
                  Total
                </SummarySubText>
                <SummarySubText color="#009EF7" fontSize={16}>
                  {total}
                </SummarySubText>
              </p>
            </div>
          </SummaryContractRight>
        </div>
      </div>

      <div className="row mb-5"></div>
    </div>
  )
}

export default Summary
