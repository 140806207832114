import React from 'react'

import { Container } from './styled'

interface TooltipProps {
  title: string
  className?: string
  position?: string
  width?: number
  type?: 'select'
  widthContainer?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  className,
  children,
  width,
  widthContainer
}) => {
  return (
    <Container
      widthContainer={widthContainer}
      width={width}
      className={className}
    >
      {children}
      <span>{title}</span>
    </Container>
  )
}

export default Tooltip
