interface ITableWithItems {
  actualSelectedDishes: ISelectedDish[]
}
const TableWithItems = ({ actualSelectedDishes }: ITableWithItems) => {
  return (
    <table
      id="kt_datatable"
      className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
    >
      <thead className="border-bottom border-end">
        <tr>
          <th>Tipo</th>
          <th>Item</th>
        </tr>
      </thead>
      <tbody>
        {actualSelectedDishes?.map(dish => {
          return (
            <tr key={dish.dish_id}>
              <td>{dish.dish_type_name}</td>
              <td>{dish.dish_name}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableWithItems
