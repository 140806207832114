export const apiCreate = (): string => '/commercial/clients/create/'
export const apiList = (id?: string): string =>
  !id ? '/commercial/clients/' : `/commercial/clients/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/clients/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/clients/delete/${id}`

export const apiStudentDelete = (id: string): string =>
  `/commercial/students/delete/${id}`

export const apiGetPdfContract = (id: string): string =>
  `/commercial/contracts/generatePdf/${id}`
