import React from 'react'
import ReactDOM from 'react-dom'

import './assets/sass/style.scss'
import './assets/sass/style.react.scss'
import './assets/sass/init.scss'
import './assets/sass/plugins.scss'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
