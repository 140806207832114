import { FaTrashAlt } from 'react-icons/fa'
import styled from 'styled-components'

export const FormContainer = styled.div``

export const IconRemove = styled(FaTrashAlt).attrs(() => ({
  size: '16px'
}))`
  cursor: pointer;
`
