import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { NotFoundPage } from '../../pages/NotFound'
export const CustomSwitch: React.FC = ({ children }) => {
  return (
    <Switch>
      {children}
      <Route component={NotFoundPage} />
    </Switch>
  )
}
