import React from 'react'
import { useTransition } from 'react-spring'

import Toast from './Toast'
import { ToastMessage } from '../../hooks/toast'
import { Container } from './styles'

interface TostContainerProps {
  messages: ToastMessage[]
}

const ToastContainer: React.FC<TostContainerProps> = ({ messages }) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: { top: '120%' },
      enter: { top: '0%' },
      leave: { top: '120%' }
    }
  )
  return (
    <Container>
      {messagesWithTransitions.map(({ item, key, props }) => (
        <Toast key={key} style={props} message={item} />
      ))}
    </Container>
  )
}

export default ToastContainer
