import React, { HTMLAttributes } from 'react'
import { PageLink, PageTitle } from '../../assets/layout/core'
import { useLoading } from '../../hooks/loading'
import { Loading } from '../Loading'
import { ContainerWrapper } from './style'

type ParamsPush = {
  id: string
  value: string
}
export interface ToolsContainerProps {
  name: string
  to: string
  icon: string
  hasParams?: false | ParamsPush
  color?: string
  handleOnClick?: <T>(currentValue: T | any) => void
}

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  pageTitle: string
  breadcrumb?: any
  // breadcrumb?:PageLink[]
  tools?: ToolsContainerProps[]
  styleContent?: HTMLAttributes<HTMLDivElement>
}

const Container: React.FC<ContainerProps> = ({
  pageTitle,
  breadcrumb,
  tools,
  children,
  ...props
}) => {
  const { loading } = useLoading()

  return (
    <ContainerWrapper {...props} className="container-fluid">
      <Loading isActive={loading} />
      <PageTitle breadcrumbs={breadcrumb} tools={tools.filter(tool => tool)}>
        {pageTitle}
      </PageTitle>
      <div className="card mb-5 mb-xl-10">
        <div className="card-body p-9">{children}</div>
      </div>
    </ContainerWrapper>
  )
}

export default Container
