import { FinishedPage } from 'pages/Commercial/Finished'
import { Route, Switch } from 'react-router-dom'
import { NotFoundPage } from '../pages/NotFound'
import { UserContractsRouter } from './commercial/UserContractsRouter'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/contract" component={UserContractsRouter} />
      <Route path="/finished" component={FinishedPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
