export const deleteEmptyFields = (object: any) => {
  for (const field in object) {
    if (
      object[field] === undefined ||
      object[field] === null ||
      object[field].length === 0
    ) {
      delete object[field]
    }
  }
  return object
}
