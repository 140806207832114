import React from 'react'

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div
      className="post d-flex flex-column-fluid"
      id="kt_post"
      style={{ marginTop: '15px' }}
    >
      {children}
    </div>
  )
}

export default DefaultLayout
