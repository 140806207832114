import styled from 'styled-components'

export const CardSummary = styled.div`
  position: relative;
  /* padding: 1rem; */
  border: 1px solid #00a3ff;
  background-color: #f1faff;
  border-style: dashed;
  padding: 10px 12px;

  border-radius: 1rem;
  h4 {
    font-size: 1.4rem;
    color: #3f4254;
  }
`

export const CardSubText = styled.p`
  background: #f5f8fa;
  color: #a1a5b7;
  font-weight: 500;
  padding: 0.5rem;
  font-size: 12px;
  text-align: center;
`

interface IPropsSummarySubText {
  fontSize?: number
  color?: string
}

export const SummarySubText = styled.span<IPropsSummarySubText>`
  font-weight: 600;
  color: ${({ color }) => color || '#a1a5b7'};
  font-size: ${({ fontSize }) => fontSize + 'px' || '13px'};
`
export const SummaryContractRight = styled.div`
  align-self: flex-start;
  border: 1px solid #a1a5b7;
  background-color: #f5f8fa4d;
  border-radius: 1rem;
  border-style: dashed;
  padding: 10px 14px;
  & > h3 {
    padding: 0.5rem 1rem;
  }
`
