import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import GlobalStyle from './styles/global'
import Routes from './routes'
import AppProvider from './hooks'
import { LayoutProvider } from './assets/layout/core'
import { MasterInit } from './assets/layout/MasterInit'
import { PageDataProvider } from './assets/layout/core/PageData'

const App: React.FC = () => (
  <Router>
    <LayoutProvider>
      <PageDataProvider>
        <AppProvider>
          <QueryParamProvider ReactRouterRoute={Route}>
            <GlobalStyle />
            <Routes />
            <MasterInit />
          </QueryParamProvider>
        </AppProvider>
      </PageDataProvider>
    </LayoutProvider>
  </Router>
)

export default App
