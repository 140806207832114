import styled from 'styled-components'

export const PageContainer = styled.div`
  background-color: #eaf3d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    padding: 40px;
    text-align: center;

    h1 {
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 1em;
      :last-child {
        margin-bottom: 50px;
      }
    }
    a {
      :last-child {
        margin-left: 15px;
      }
    }
  }
`
