import styled from 'styled-components'

export const FormContainer = styled.div`
  input:read-only {
    background: #cccccc !important;
  }
  select:disabled {
    background: #cccccc !important;
  }
`
